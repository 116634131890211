import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Złożone, tekstowe',
  image: 'template-icon-13.png',
  template: `
  <p>
    <span
      style="font-size: 21.2pt;"
      data-mce-style="font-size: 21.2pt;"
       data-type="complex-text"
    > <strong>Wpisz treść zadania.</strong>&nbsp;Możesz zmieniać wielkość miejsca na odpowiedź. </span>
  </p>
  <p>
    <span
      style="font-size: 21.2pt;"
      data-mce-style="font-size: 21.2pt;"
    >
      <span class="answer-field">
        <span
          class="answer-field__table answer-field__table--checker mceNonEditable"
          contenteditable="false"
        >
          <span class="answer-field__tbody answer-field__tbody--checker">
            <span class="answer-field__tr answer-field__tr--checker">
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
            </span>
            <span class="answer-field__tr answer-field__tr--checker">
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
            </span>
          </span>
        </span>
      </span>
    </span>
  </p>
  <p>
    <span
      style="font-size: 21.2pt;"
      data-mce-style="font-size: 21.2pt;"
    > Jeśli chcesz dodać element graficzny, użyj przycisku <strong>Wstaw grafikę</strong>. </span>
  </p>
  <p>
    <span
      style="font-size: 21.2pt;"
      data-mce-style="font-size: 21.2pt;"
    >
      <span class="answer-field">
        <span
          class="answer-field__table answer-field__table--checker mceNonEditable"
          contenteditable="false"
        >
          <span class="answer-field__tbody answer-field__tbody--checker">
            <span class="answer-field__tr answer-field__tr--checker">
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
            </span>
            <span class="answer-field__tr answer-field__tr--checker">
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
              <span class="answer-field__td answer-field__td--checker"></span>
            </span>
          </span>
        </span>
      </span>
    </span>
  </p>
  `
};

export default template;
