
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Modal, ModalBody, ModalHeader } from '@/shared/components';
import { NeSpinner, NeTile } from '@ne/ne-vue2-lib';
import {
  openExerciseTemplates,
  openExerciseTemplatesEarlySchoolEducation,
  templates,
  templatesEarlySchoolEducation
} from './_templates';
import { AnswersType, ExerciseTemplate } from '@/models/exercises';
import { resourcePath } from '@/config';

@Component({
  name: 'template-picker',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    NeTile,
    NeSpinner
  }
})
export default class TemplatePicker extends Vue {
  @Prop() value: boolean;
  @Prop() earlySchoolEducation: boolean | null;
  @Prop() isExerciseOldType: boolean;
  @Prop() isRedactor: boolean;
  @Prop() answersType: AnswersType;

  loadedImagesAmount: number = 0;

  get templates (): ExerciseTemplate[] {
    switch (this.answersType) {
    case AnswersType.CLOSED:
      if (this.isRedactor) {
        return this.earlySchoolEducation ? openExerciseTemplatesEarlySchoolEducation : templates;
      }
      return this.earlySchoolEducation ? openExerciseTemplatesEarlySchoolEducation : openExerciseTemplates;
    case AnswersType.BUNDLES_EXAM:
      return this.earlySchoolEducation ? openExerciseTemplatesEarlySchoolEducation : openExerciseTemplates;
    case AnswersType.OPEN:
    case AnswersType.OPEN_CLOSED:
      return this.earlySchoolEducation ? openExerciseTemplatesEarlySchoolEducation : templates;
    default:
      return this.earlySchoolEducation ? templatesEarlySchoolEducation : templates;
    }
  }

  get newExerciseClass (): string {
    if (!this.earlySchoolEducation && !this.isExerciseOldType) {
      return this.answersType === AnswersType.BUNDLES_EXAM ? '' : 'gt-template-picker__new-exercise-templates';
    }
    return '';
  }

  get areImagesLoaded (): boolean {
    return this.loadedImagesAmount === this.templates.length;
  }

  imageSource (image: string): string {
    return `${resourcePath}assets/images/template-icons/${image}`;
  }

  incrementLoadedImagesAmount (): void {
    this.loadedImagesAmount++;
  }

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  close (): void {
    this.loadedImagesAmount = 0;
    this.input(false);
  }

  @Emit()
  insert (template: ExerciseTemplate): string {
    this.close();
    return template.template;
  }

  selectTemplate (template: ExerciseTemplate): void {
    this.insert(template);
  }
}
