import { ExerciseTemplate } from '@/models/exercises';
import { square } from './images';

export const template: ExerciseTemplate = {
  title: 'Dwie kolumny &#8211; dwa polecenia',
  image: 'template-icon-4.png',
  template: `
    <table class="tiny-table--hidden" width="640" style="width: 640px;" data-type="two-cols-two-tasks">
        <tbody>
            <tr >
                <td style="padding-left: 0;">1. Wpisz treść zadania.</td>
                <td style="padding-left: 0;">2. Wpisz treść zadania.</td>
            </tr>
            <tr >
                <td style="border-color:#fff;">
                    <ol style="list-style-type: none; margin: 0 0 15px 15px;">
                        <li style="margin: 0 0 2px 0;"><img width="14" style="width: 14px" src="${square}" /> <strong>A.</strong> Wpisz treść.</li>
                        <li style="margin: 0 0 2px 0;"><img width="14" style="width: 14px" src="${square}" /> <strong>B.</strong> Wpisz treść.</li>
                    </ol>
                </td>
                <td style="border-color:#fff;">
                    <ol style="list-style-type: none; margin: 0 0 15px 15px;">
                        <li style="margin: 0 0 2px 0;"><img width="14" style="width: 14px" src="${square}" /> <strong>A.</strong> Wpisz treść.</li>
                        <li style="margin: 0 0 2px 0;"><img width="14" style="width: 14px" src="${square}" /> <strong>B.</strong> Wpisz treść.</li>
                    </ol>
                </td>
            </tr>
        </tbody>
    </table>`
};

export default template;
