import { ExerciseTemplate } from '@/models/exercises';
import { exerciseImage } from './images';

export const template: ExerciseTemplate = {
  title: '1, 2, 3, 4 z grafiką',
  image: 'template-icon-20.png',
  template: `
    <p
      style="padding: 0;"
      data-mce-style="padding: 0;"
    >
      <span
        style="font-size: 21.2pt;"
        data-mce-style="font-size: 21.2pt;"
      > <strong>Wpisz treść zadania.</strong> Zaznacz szablon i użyj przycisku <strong>Wstaw grafikę</strong>. Możesz zmienić format numeracji, korzystając z przycisku <strong>Lista numerowana</strong>. </span>
    </p>
    <table
      class="tableNotFix tiny-table--hidden mce-item-table"
      width="640"
      data-type="graphic-1234"
    >
      <tbody>
        <tr>
          <td
            width="35"
            style="width: 35px; vertical-align: top;"
            data-mce-style="width: 35px; vertical-align: top;"
          >
            <span
              style="font-size: 21.2pt;"
              data-mce-style="font-size: 21.2pt;"
            >
              <strong>1.</strong>
            </span>
          </td>
          <td
            width="125"
            style="width: 125px; vertical-align: top;"
            data-mce-style="width: 125px; vertical-align: top;"
          >
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </td>
          <td
            width="35"
            style="width: 35px; vertical-align: top;"
            data-mce-style="width: 35px; vertical-align: top;"
          >
            <span
              style="font-size: 21.2pt;"
              data-mce-style="font-size: 21.2pt;"
            >
              <strong>2.</strong>
            </span>
          </td>
          <td
            width="125"
            style="width: 125px; vertical-align: top;"
            data-mce-style="width: 125px; vertical-align: top;"
          >
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </td>
          <td
            width="35"
            style="width: 35px; vertical-align: top;"
            data-mce-style="width: 35px; vertical-align: top;"
          >
            <span
              style="font-size: 21.2pt;"
              data-mce-style="font-size: 21.2pt;"
            >
              <strong>3.</strong>
            </span>
          </td>
          <td
            width="125"
            style="width: 125px; vertical-align: top;"
            data-mce-style="width: 125px; vertical-align: top;"
          >
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </td>
          <td
            width="35"
            style="width: 35px; vertical-align: top;"
            data-mce-style="width: 35px; vertical-align: top;"
          >
            <span
              style="font-size: 21.2pt;"
              data-mce-style="font-size: 21.2pt;"
            >
              <strong>4.</strong>
            </span>
          </td>
          <td
            width="125"
            style="width: 125px; vertical-align: top;"
            data-mce-style="width: 125px; vertical-align: top;"
          >
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </td>
        </tr>
      </tbody>
    </table>
  `
};

export default template;
