import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Obliczenia pisemne',
  image: 'template-icon-18.png',
  template: `
    <p>
      <span
        style="font-size: 21.2pt;"
        data-mce-style="font-size: 21.2pt;"
      >
        <strong>Wpisz treść zadania.</strong>
        Możesz też skopiować gotowy tekst do generatora, korzystając ze skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V).
      </span>
    </p>
    <table
      class="tiny-table tiny-table--hidden mce-item-table"
      width="662"
      data-type="written-calculations"
    >
      <tbody>
        <tr>
          <td
            style="width: 25%;"
            data-mce-style="width: 25%;"
          >
            <table
              class="mce-item-table tiny-table tiny-table--hidden"
            >
              <tbody>
                <tr>
                  <td
                    style="width: 50px; text-align: right;"
                    data-mce-style="width: 50px; text-align: right;"
                  >
                    <p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >X</span><br><span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >+ Y</span>
                    </p><hr><p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >Z</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td
            style="width: 25%;"
            data-mce-style="width: 25%;"
          >
            <table
              class="mce-item-table tiny-table tiny-table--hidden"
            >
              <tbody>
                <tr>
                  <td
                    style="width: 70px; text-align: right;"
                    data-mce-style="width: 70px; text-align: right;"
                  >
                    <p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >XX</span><br><span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >+ YY</span>
                    </p><hr><p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >ZZ</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td
            style="width: 25%;"
            data-mce-style="width: 25%;"
          >
            <table
              class="mce-item-table tiny-table tiny-table--hidden"
            >
              <tbody>
                <tr>
                  <td
                    style="width: 70px; text-align: right;"
                    data-mce-style="width: 70px; text-align: right;"
                  >
                    <p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >XX</span><br><span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >− YY</span>
                    </p><hr><p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >ZZ</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td
            style="width: 25%;"
            data-mce-style="width: 25%;"
          >
            <table
              class="mce-item-table tiny-table tiny-table--hidden"
            >
              <tbody>
                <tr>
                  <td
                    style="width: 120px; text-align: right;"
                    data-mce-style="width: 120px; text-align: right;"
                  >
                    <p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >XXXX</span><br><span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >+ YYYY</span>
                    </p><hr><p>
                      <span
                        style="font-size: 21.2pt;"
                        data-mce-style="font-size: 21.2pt;"
                      >ZZZZ</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `
};

export default template;
