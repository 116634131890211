import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Dwie kolumny &#8211; na dopasowanie',
  image: 'template-icon-6.png',
  template: `
    <p style="padding: 0">
        <strong>Wpisz treść zadania.</strong> Możesz też skopiować gotowy tekst do generatora, korzystając ze&nbsp;skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V).
    </p>
    <table class="tableNotFix tiny-table--hidden" data-type="two-columns-assignment">
        <tbody>
            <tr>
                <td width="320" style="width: 320px; vertical-align: top;">
                    <ol style="font-weight: bold;">
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                    </ol>
                    <br>
                </td>
                <td width="320" style="width: 320px; vertical-align: top;">
                    <ol style="list-style-type: upper-alpha; font-weight: bold;">
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                        <li>
                            <span style="font-weight: normal;">Wpisz treść</span>
                        </li>
                    </ol>
                    <br>
                </td>
            </tr>
        </tbody>
    </table>`
};

export default template;
