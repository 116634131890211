import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'A, B, C, D &#8211; dwa polecenia',
  image: 'template-icon-7.png',
  template: `
    <p style="padding: 0">
        <strong>Wpisz treść zadania.</strong> Możesz też skopiować gotowy tekst do generatora, korzystając ze&nbsp;skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V).
    </p>
    <p style="padding: 0">Możesz zmienić format numeracji, korzystając z przycisku Lista numerowana. <strong>A / B</strong></p>
    <table class="tiny-table--hidden table-abcd" data-type="two-tasks-abcd">
        <tbody>
            <tr>
                <td width="200" style="width: 200px; text-align: center;">
                    <strong style="display:inline-block; vertical-align: middle;">A.</strong>
                    <span class="rendered-latex" contenteditable="false"><span class="selectable" style="display: none">\\frac{ xx }{ xx }</span>
                        <span class="ML__mathlive"><span class="ML__strut" style="height:1.1em;"></span><span class="ML__strut--bottom" style="height:1.79em;vertical-align:-0.69em;"></span><span class="ML__base"><span><span class="sizing nulldelimiter mopen"></span><span class="vlist mfrac"><span style="top:-0.68em;"><span><span class="ML__mathit">xx</span></span></span><span style="top:-0.23em;"><span class="frac-line"></span></span><span style="top:0.68em;"><span><span class="ML__mathit">xx</span></span></span></span><span class="sizing nulldelimiter mclose"></span></span></span></span>
                    </span>
                </td>
                <td width="200" style="width: 200px; text-align: center;">
                    <strong style="display:inline-block; vertical-align: middle;">B.</strong>
                    <span class="rendered-latex" contenteditable="false"><span class="selectable" style="display: none">\\frac{ xx }{ xx }</span>
                        <span class="ML__mathlive"><span class="ML__strut" style="height:1.1em;"></span><span class="ML__strut--bottom" style="height:1.79em;vertical-align:-0.69em;"></span><span class="ML__base"><span><span class="sizing nulldelimiter mopen"></span><span class="vlist mfrac"><span style="top:-0.68em;"><span><span class="ML__mathit">xx</span></span></span><span style="top:-0.23em;"><span class="frac-line"></span></span><span style="top:0.68em;"><span><span class="ML__mathit">xx</span></span></span></span><span class="sizing nulldelimiter mclose"></span></span></span></span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <p style="padding: 0">Za pomocą przycisku Lista wypunktowana wstawisz punktory. <strong>C / D</strong></p>
    <table class="tiny-table--hidden table-abcd">
        <tbody>
            <tr>
                <td width="200" style="width: 200px; text-align: center;">
                    <strong style="display:inline-block; vertical-align: middle;">C.</strong>
                    <span class="rendered-latex" contenteditable="false"><span class="selectable" style="display: none">\\frac{ xx }{ xx }</span>
                        <span class="ML__mathlive"><span class="ML__strut" style="height:1.1em;"></span><span class="ML__strut--bottom" style="height:1.79em;vertical-align:-0.69em;"></span><span class="ML__base"><span><span class="sizing nulldelimiter mopen"></span><span class="vlist mfrac"><span style="top:-0.68em;"><span><span class="ML__mathit">xx</span></span></span><span style="top:-0.23em;"><span class="frac-line"></span></span><span style="top:0.68em;"><span><span class="ML__mathit">xx</span></span></span></span><span class="sizing nulldelimiter mclose"></span></span></span></span>
                    </span>
                </td>
                <td width="200" style="width: 200px; text-align: center;">
                    <strong style="display:inline-block; vertical-align: middle;">D.</strong>
                    <span class="rendered-latex" contenteditable="false"><span class="selectable" style="display: none">\\frac{ xx }{ xx }</span>
                        <span class="ML__mathlive"><span class="ML__strut" style="height:1.1em;"></span><span class="ML__strut--bottom" style="height:1.79em;vertical-align:-0.69em;"></span><span class="ML__base"><span><span class="sizing nulldelimiter mopen"></span><span class="vlist mfrac"><span style="top:-0.68em;"><span><span class="ML__mathit">xx</span></span></span><span style="top:-0.23em;"><span class="frac-line"></span></span><span style="top:0.68em;"><span><span class="ML__mathit">xx</span></span></span></span><span class="sizing nulldelimiter mclose"></span></span></span></span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>`
};

export default template;
