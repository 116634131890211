import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Obliczenia',
  image: 'template-icon-16.png',
  template: `
  <p>
    <strong>Wpisz treść zadania.</strong>&nbsp;Możesz też skopiować gotowy tekst do generatora, korzystając ze skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V).
  </p>
  <table
    class="tiny-table tiny-table--hidden mce-item-table"
    width="662"
     data-type="calculations"
  >
    <tbody>
      <tr>
        <td
          style="width: 30%;"
          data-mce-style="width: 30%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  X + Y =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td
          style="width: 30%;"
          data-mce-style="width: 30%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  X + Y + Z =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td
          style="width: 40%;"
          data-mce-style="width: 40%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  XXX + YYY =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td
          style="width: 30%;"
          data-mce-style="width: 30%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  XX + YY =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td
          style="width: 30%;"
          data-mce-style="width: 30%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  XX + Y =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td
          style="width: 40%;"
          data-mce-style="width: 40%;"
        >
          <table
            class="tiny-table tiny-table--hidden mce-item-table"
          >
            <tbody>
              <tr>
                <td>
                  XX + YYY =
                </td>
                <td>
                  <span class="answer-field">
                    &ZeroWidthSpace;
                    <span
                      class="answer-field__table answer-field__table--checker mceNonEditable"
                      contenteditable="false"
                    >
                      <span class="answer-field__tbody answer-field__tbody--checker">
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                        <span class="answer-field__tr answer-field__tr--checker">
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                          <span class="answer-field__td answer-field__td--checker"></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `
};

export default template;
