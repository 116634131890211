import { ExerciseTemplate } from '@/models/exercises';
import { exerciseImage } from './images';

export const template: ExerciseTemplate = {
  title: 'Tekst z grafiką',
  image: 'template-icon-1.png',
  template: `
    <table class="tableNotFix tiny-table--hidden" data-type="text-with-graphic">
        <tbody>
            <tr>
                <td width="520" style="width: 520px; vertical-align: top;">
                    <strong>Wpisz treść zadania.</strong> Możesz też skopiować gotowy tekst do&nbsp;generatora, korzystając ze skrótów klawiszowych Kopiuj (Ctrl+C) i&nbsp;Wklej (Ctrl+V).
                </td>
                <td width="120" style="width: 120px; vertical-align: top; padding: 0 0 0 20px;" align="right">
                    <img src="${exerciseImage}" alt="" width="100" />
                </td>
            </tr>
        </tbody>
    </table>`
};

export default template;
