import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Na uzasadnianie',
  image: 'template-icon-2.png',
  template: `
    <p style="padding: 0">
        <strong>Jeśli chcesz zmienić wygląd tabeli – zaznacz ją, wyświetli się podręczne menu.</strong> Kolor linii i tło tabeli zmienisz w Właściwościach tabeli – zaawansowane.
    </p>
    <div style="margin-top: 4px">
        <table class="tableNotFix" data-type="justification">
            <tbody>
                <tr style="height: 21px;">
                    <td width="30" style="width: 30px; text-align: center; height: 21px;">
                        <strong>A.</strong>
                    </td>
                    <td width="215" style="width: 215px; height: 21px;">
                        Wpisz treść
                    </td>
                    <td width="90" style="width: 90px; height: 63px; text-align: center; vertical-align: middle;" rowspan="3">
                        ponieważ
                    </td>
                    <td width="35" style="width: 35px; height: 21px; text-align: center;">
                        <strong>1.</strong>
                    </td>
                    <td width="270" style="width: 270px; height: 21px;">
                        lub skopiuj (Ctrl+C) i (Ctrl+V).
                    </td>
                </tr>
                <tr style="height: 21px;">
                    <td width="30" style="width: 30px; text-align: center; height: 21px;" rowspan="2">
                        <strong>B.</strong>
                    </td>
                    <td width="215" style="width: 215px; height: 42px; vertical-align: middle;" rowspan="2">
                        Wpisz treść
                    </td>
                    <td width="35" style="width: 35px; height: 21px; text-align: center;">
                        <strong>2.</strong>
                    </td>
                    <td width="270" style="width: 270px; height: 21px;">
                        lub skopiuj (Ctrl+C) i (Ctrl+V).
                    </td>
                </tr>
                <tr style="height: 21px;">
                    <td width="35" style="width: 35px; height: 21px; text-align: center;">
                        <strong>3.</strong>
                    </td>
                    <td width="270" style="width: 270px; height: 21px;">
                        lub skopiuj (Ctrl+C) i (Ctrl+V).
                    </td>
                </tr>
            </tbody>
        </table>
    </div>`
};

export default template;
