import { ExerciseTemplate } from '@/models/exercises';
import { exerciseImage } from './images';

export const template: ExerciseTemplate = {
  title: 'Tabela z grafiką',
  image: 'template-icon-12.png',
  template: `
    <p
      style="padding: 0;"
      data-mce-style="padding: 0;"
       data-type="table-with-graphic"
    >
      <strong>Wpisz treść zadania.</strong> Jeśli chcesz dodać element, użyj przycisku <strong>Wstaw grafikę</strong>. Jeśli chcesz usunąć element - zaznacz go i użyj przycisku <strong>Delete</strong>.
    </p><div
      style="margin-top: 4px;"
      data-mce-style="margin-top: 4px;"
    >
      <table
        class="tableNotFix mce-item-table"
        width="662"
      >
        <tbody>
          <tr>
            <td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              <img
                src="${exerciseImage}"
                alt=""
                width="50%"
              >
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              <img
                src="${exerciseImage}"
                alt=""
                width="50%"
              >
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              <img
                src="${exerciseImage}"
                alt=""
                width="50%"
              >
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              <img
                src="${exerciseImage}"
                alt=""
                width="50%"
              >
            </td>
          </tr><tr>
            <td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td>
          </tr><tr>
            <td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td>
          </tr><tr>
            <td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td><td
              style="text-align: center;"
              data-mce-style="text-align: center;"
            >
              Wpisz treść
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `
};

export default template;
