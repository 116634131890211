import { ExerciseTemplate } from '@/models/exercises';
import { exerciseImage } from './images';

export const template: ExerciseTemplate = {
  title: 'Na przyporządkowanie',
  image: 'template-icon-14.png',
  template: `
    <p>
      <strong>Wpisz treść zadania.</strong>
      Możesz też skopiować gotowy tekst do generatora, korzystając ze skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V). Do wstawiania grafiki użyj przycisku
      <strong>Wstaw grafikę</strong>.
    </p>
    <table
      width="662"
      class="mce-item-table tiny-table tiny-table--hidden"
       data-type="assignment"
    >
      <tbody>
        <tr>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            A.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            1.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
        </tr>
        <tr>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            B.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            2.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
        </tr>
        <tr>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            C.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            3.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
        </tr>
        <tr>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            D.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
          <td
            style="width: 40px"
            data-mce-style="width: 40px"
          >
            4.
          </td>
          <td
            style="width: 280px"
            data-mce-style="width: 280px"
          >
            <img src="${exerciseImage}" alt="" width="100" />
          </td>
        </tr>
      </tbody>
    </table>
  `
};

export default template;
