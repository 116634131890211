import { ExerciseTemplate } from '@/models/exercises';
import template1 from './template-1';
import template2 from './template-2';
import template3 from './template-3';
import template4 from './template-4';
import template6 from './template-6';
import template7 from './template-7';
import template12 from './template-12';
import template13 from './template-13';
import template14 from './template-14';
import template15 from './template-15';
import template16 from './template-16';
import template18 from './template-18';
import template19 from './template-19';
import template20 from './template-20';

export const templates: ExerciseTemplate[] = [template1];

export const openExerciseTemplates: ExerciseTemplate[] = [
  template1,
  template7,
  template4,
  template6,
  template3,
  template2
];

export const openExerciseTemplatesEarlySchoolEducation: ExerciseTemplate[] = [
  template12,
  template13,
  template14,
  template18,
  template19,
  template20,
  template15,
  template16
];

export const templatesEarlySchoolEducation: ExerciseTemplate[] = [
  template12,
  template13,
  template14,
  template15,
  template16,
  template19,
  template18
];
