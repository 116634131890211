import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Na przyporządkowanie',
  image: 'template-icon-3.png',
  template: `
    <p style="padding: 0">
        <strong>Wpisz treść zadania.</strong> Możesz też skopiować gotowy tekst do generatora, korzystając ze&nbsp;skrótów klawiszowych Kopiuj (Ctrl+C) i Wklej (Ctrl+V).
    </p>
    <table class="tiny-table--hidden" width="640" style="width: 640px;" data-type="assignment">
        <tbody>
            <tr>
                <td >
                <strong style="display: inline-block; vertical-align: top;">A.</strong> Treść
                </td>
                <td>
                <strong style="display: inline-block; vertical-align: top;">B.</strong> Treść
                </td>
                <td>
                <strong style="display: inline-block; vertical-align: top;">C.</strong> Treść
                </td>
                <td>
                <strong style="display: inline-block; vertical-align: top;">D.</strong> Treść
                </td>
                <td>
                <strong style="display: inline-block; vertical-align: top;">E.</strong> Treść
                </td>
                <td>
                <strong style="display: inline-block; vertical-align: top;">F.</strong> Treść
                </td>
            </tr>
        </tbody>
    </table>
    <table class="tiny-table--hidden table-na-przyporzadkowanie">
        <tbody>
            <tr>
                <td style="text-align: right;"><strong>I.</strong></td>
                <td>Wpisz treść</td>
                <td style="padding-left: 50px;"><strong>A/B/C/D/E/F</strong></td>
            </tr>
            <tr>
                <td style="text-align: right;"><strong>II.</strong></td>
                <td>Wpisz treść</td>
                <td style="padding-left: 50px;"><strong>A/B/C/D/E/F</strong></td>
            </tr>
            <tr>
                <td style="text-align: right;"><strong>III.</strong></td>
                <td>Wpisz treść</td>
                <td style="padding-left: 50px;"><strong>A/B/C/D/E/F</strong></td>
            </tr>
        </tbody>
    </table>`
};

export default template;
