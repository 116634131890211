import { ExerciseTemplate } from '@/models/exercises';

export const template: ExerciseTemplate = {
  title: 'Proste, tekstowe',
  image: 'template-icon-19.png',
  template: `
  <p
    style="padding: 0;"
    data-mce-style="padding: 0;"
  >
    <strong>Wpisz treść zadania.</strong>
  </p>
  <p
    style="padding: 0;"
    data-mce-style="padding: 0;"
  >
    Możesz zmieniać wielkość miejsca na odpowiedź i wielkość czcionki w poleceniu i treści zadania.
  </p>
  <div
    style="margin-top: 4px;"
    data-mce-style="margin-top: 4px;"
  >
    <table
      class="tableNotFix tiny-table--hidden tiny-table mce-item-table"
      width="662"
      data-type="simple-text"
    >
      <tbody>
        <tr
          style="height: 76px;"
          data-mce-style="height: 76px;"
        >
          <td
            style="width: 107px;"
            data-mce-style="width: 107px;"
          >
            Obliczenie:
          </td>
          <td>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </td>
        </tr>
        <tr
          style="height: 76px;"
          data-mce-style="height: 76px;"
        >
          <td
            style="width: 107px;"
            data-mce-style="width: 107px;"
          >
            Odpowiedź:
          </td>
          <td>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  `
};

export default template;
