import { ExerciseTemplate } from '@/models/exercises';
import { exerciseImage } from './images';

export const template: ExerciseTemplate = {
  title: 'Grafika z opisem',
  image: 'template-icon-15.png',
  template: `
  <p>
    <strong>Wpisz treść zadania.</strong>&nbsp;Jeśli chcesz dodać element graficzny, użyj przycisku <strong>Wstaw grafikę</strong>.
  </p>
  <table
    class="tiny-table tiny-table--hidden mce-item-table"
    width="662"
    data-type="graphic-with-text"
  >
    <tbody>
      <tr>
        <td
          style="width: 50%; text-align: center;"
          data-mce-style="width: 50%; text-align: center;"
        >
          <p>
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </p>
          <p>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </p>
        </td>
        <td
          style="width: 50%; text-align: center;"
          data-mce-style="width: 50%; text-align: center;"
        >
          <p>
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </p>
          <p>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 50%; text-align: center;"
          data-mce-style="width: 50%; text-align: center;"
        >
          <p>
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </p>
          <p>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </p>
        </td>
        <td
          style="width: 50%; text-align: center;"
          data-mce-style="width: 50%; text-align: center;"
        >
          <p>
            <img
              src="${exerciseImage}"
              alt=""
              width="100"
            >
          </p>
          <p>
            <span class="answer-field">
              &ZeroWidthSpace;
              <span
                class="answer-field__table answer-field__table--checker mceNonEditable"
                contenteditable="false"
              >
                <span class="answer-field__tbody answer-field__tbody--checker">
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                  <span class="answer-field__tr answer-field__tr--checker">
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                    <span class="answer-field__td answer-field__td--checker"></span>
                  </span>
                </span>
              </span>
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  `
};

export default template;
